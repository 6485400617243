import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import HeroLayout from '../components/heroLayout'
import Layout from '../components/layout'
import { Grid, Typography } from "@material-ui/core";
import { Link } from 'gatsby'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')    
    const [logoHeroImage] = get(this, 'props.data.logo.edges')
    const [BIBLECLUBSHeroImage] = get(this, 'props.data.BIBLECLUBS.edges')
    const [TRAININGHeroImage] = get(this, 'props.data.TRAINING.edges')
    const [CAMPSHeroImage] = get(this, 'props.data.CAMPS.edges')
    const [HELPINCOMMUNITIESHeroImage] = get(this, 'props.data.HELPINCOMMUNITIES.edges')
    

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={siteTitle} />
          <HeroLayout data={logoHeroImage.node} />
          <div className="wrapper">            
            <h2 className="section-headline">Home</h2>
            <div style={{ marginTop: 10, padding: 10 }}>
              <Grid container spacing={10} justify="center">                
                <Grid item key="BIBLE CLUBS">
                  <Card className="root" variant="outlined">
                    <CardMedia className="media"
                      component="img"
                      alt="BIBLE CLUBS"                      
                      image={BIBLECLUBSHeroImage.node.backgroundImage.fluid.src}
                      title="BIBLE CLUBS"
                    />                                      
                    <CardContent>                
                      <Typography variant="h5" component="h2">
                        BIBLE CLUBS
                      </Typography>                
                      <Typography variant="body2" component="p">
                        Teaching the Word of God to children through<br/>
                        Bible lessons and creative activities, in communities,<br/>
                        schools, pre-primary schools, aftercares and on farms.
                      </Typography>
                    </CardContent>                    
                    <CardActions>                      
                      <Link to="/WhatWeDoBIBLECLUBS">
                        <Button size="small">Learn More</Button>
                      </Link>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item key="TRAINING">
                  <Card className="root" variant="outlined">                    
                    <CardMedia className="media"
                        component="img"
                        alt="TRAINING"                        
                        image={TRAININGHeroImage.node.backgroundImage.fluid.src}
                        title="TRAINING"
                    /> 
                    <CardContent>                
                      <Typography variant="h5" component="h2">
                        TRAINING
                      </Typography>                
                      <Typography variant="body2" component="p">
                        Training and equipping Bible club leaders and<br/>
                        others in children’s work through workshops,<br/>
                        courses and informal groups.
                      </Typography>
                    </CardContent>                    
                    <CardActions>
                      <Link to="/WhatWeDoTRAINING">
                        <Button size="small">Learn More</Button>
                      </Link>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item key="CAMPS">
                  <Card className="root" variant="outlined">                    
                    <CardMedia className="media"
                      component="img"
                      alt="CAMPS"                      
                      image={CAMPSHeroImage.node.backgroundImage.fluid.src}
                      title="CAMPS"
                    /> 
                    <CardContent>                
                      <Typography variant="h5" component="h2">
                        CAMPS
                      </Typography>                
                      <Typography variant="body2" component="p">
                        Building of deeper relationships, disciple making and<br/>
                        leadership development for children of our Bible clubs<br/>
                        <br/>
                      </Typography>
                    </CardContent>                    
                    <CardActions>
                      <Link to="/WhatWeDoCAMPS">
                        <Button size="small">Learn More</Button>
                      </Link>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item key="HELP IN COMMUNITIES">
                  <Card className="root" variant="outlined">                    
                    <CardMedia className="media"
                      component="img"
                      alt="HELP IN COMMUNITIES"                      
                      image={HELPINCOMMUNITIESHeroImage.node.backgroundImage.fluid.src}
                      title="HELP IN COMMUNITIES"
                    /> 
                    <CardContent>                
                      <Typography variant="h5" component="h2">
                        HELP IN COMMUNITIES
                      </Typography>                
                      <Typography variant="body2" component="p">
                        Involvement with spreading of food parcels,<br/>
                        veggie garden starter kits, help at soup kitchens<br/>
                        and supporting people in need.
                      </Typography>
                    </CardContent>                    
                    <CardActions>
                      <Link to="/WhatWeDoHELPINCOMMUNITIES">
                        <Button size="small">Learn More</Button>
                      </Link>
                    </CardActions>
                  </Card>
                </Grid>                                          
              </Grid>
            </div>
            <p>
            <strong>Truth2kids</strong> is a project of Helderberg Outreach, a registered Non Profit
              Organisation (NPO) in South Africa.
            </p>
            <p>
              The vision of <strong>Truth2kids</strong> is to reach as many children as possible with the
              message of the Gospel – so that they would get to know Jesus Christ as their personal
              Saviour and grow in a relationship with Him in order to live as His disciples.
            </p>
            <figure class="quote">
            <blockquote>
              "I am the light of the world. Whoever follows me will never walk in darkness, but will have the light of life."
            </blockquote>
            <figcaption>
              &mdash; John 8:12  </figcaption>
            </figure>
            <p>
              We are thankful for the more than 1800 kids that can be reached weekly in the different
              Bible clubs that we run on farms, in schools, aftercare centres and pre-primary schools. This is
              made possible with the help of many volunteers who are able to present well-founded Bible
              lessons, together with fun-filled and creative educational activities. Children from poorer 
              communities are also taken on camps, which they thoroughly enjoy. The camps also create a
              wonderful opportunity for building relationships and in-depth group work.              
            </p>
            <p>
              We present training to equip Bible club leaders and people in children’s work to teach the truths of
              God’s Word to children. Workshops, courses and informal training opportunities are being organised
              on a regular basis.
            </p>
            <p>
              We are also involved in the communities through helping people in need and making resources
              available to reach children ranging from the age of 4-14 years with the message of God’s love.
            </p>
            <p>
              <strong>Helderberg Outreach</strong> was founded by Helderberg Congregation, Somerset West, South Africa in 2003, and is registered as an NPO
              in 2004. 032-078 NPO.
            </p>
            <p>
              The aim is to mobilize believers to become carriers of Christ’s hope in a holistic way to a world in spiritual, material and socio-
              economic need and to transform communities. Volunteers are mobilized, motivated and equipped to fulfil their Godly calling (Acts
              1:8) and to network and form partnerships to achieve this vision. Our aim is to harness passion for Christ and transform it into
              action for His Kingdom.
            </p>            
          </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const homeQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    logo: allContentfulLayoutHeroImage(filter: {title: {regex: "/LogoWithVerse/"}}, limit: 1) {
      edges {
        node {
          title
          headline
          backgroundImage {
            fluid(
              maxWidth: 1180
              maxHeight: 400
              resizingBehavior: PAD
              background: "white"
            ) {
              ...GatsbyContentfulFluid
            }          
          }
        }
      }
    }
    BIBLECLUBS: allContentfulLayoutHeroImage(filter: {title: {regex: "/Bible clubs 5/"}}, limit: 1) {
      edges {
        node {
          title
          headline
          backgroundImage {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD              
            ) {
              ...GatsbyContentfulFluid
            }          
          }
        }
      }
    }
    CAMPS: allContentfulLayoutHeroImage(filter: {title: {regex: "/Camps 8/"}}, limit: 1) {
      edges {
        node {
          title
          headline
          backgroundImage {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD              
            ) {
              ...GatsbyContentfulFluid
            }          
          }
        }
      }
    }
    HELPINCOMMUNITIES: allContentfulLayoutHeroImage(filter: {title: {regex: "/Community 2/"}}, limit: 1) {
      edges {
        node {
          title
          headline
          backgroundImage {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD              
            ) {
              ...GatsbyContentfulFluid
            }          
          }
        }
      }
    }
    TRAINING: allContentfulLayoutHeroImage(filter: {title: {regex: "/Training 2/"}}, limit: 1) {
      edges {
        node {
          title
          headline
          backgroundImage {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
            ) {
              ...GatsbyContentfulFluid
            }          
          }
        }
      }
    }    
  }
`
